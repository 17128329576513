import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "./image_details.css";
import { Textarea } from "../../../common/components/ui/textarea";
import { Button } from "../../../common/components/ui/button";
import { PencilIcon, Sparkles, CircleX } from "../../../common/icons/icons";
import IpVaultModal from "./modal-components/IP_vault_modal";
import { useGenerativeAPI } from "../../../api/generative_ai_api";
import loading from "../../../common/components/ui/loading.gif";
import AspectRatioCardGroup from "./aspect_ratio_card";
import { GenerativeModel } from "../models/image_generator";

const ImageDetails = ({ form, errors, selectedModels, modelList, aspectRatios, onVaultSelect }: { form: any, errors: any, modelList: GenerativeModel[], selectedModels: any, aspectRatios: any, onVaultSelect: (vault: GenerativeModel | null) => void }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isIpVaultOpen, setIsIpVaultOpen] = useState(false);
    const [selectedVault, setSelectedVault] = useState<GenerativeModel | null>(selectedModels[0]);
    const { enhancePrompt } = useGenerativeAPI();
    const { setValue } = useFormContext();

    const removeSelectedVault = () => {
        onVaultSelect(null);
    }

    useEffect(() => {
        setSelectedVault(selectedModels[0] || null);
    }, [selectedModels]);

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const models = []
        if (selectedModels) {
            models.push(selectedModels[0].id);
        }
        const response = await enhancePrompt(form.getValues("prompt"), models);
        if (response && response.enhanced) {
            setValue("prompt", response.enhanced);
        }
        setIsLoading(false);
    }

    const handleAspectRatioChange = (newAspectRatio: string) => {
        setValue("aspect_ratio", newAspectRatio);
    }

    return (
        <div className="space-y-12">
            <div className="flex w-full gap-14">
                <div className="flex md:flex-row flex-col gap-12">
                    <div className="relative md:w-[519px]">
                        <Textarea
                            placeholder="Enter image prompt here"
                            className="bg-gray-100 text-black border-none h-[173px] p-5 w-full resize-none"
                            value={form.watch("prompt")}
                            onChange={(e) => setValue("prompt", e.target.value)}
                        />
                        {isLoading ? (
                            <Button
                                className="w-44 border-none absolute hover:bg-brand-yellow bottom-5 right-5"
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                            >
                                <img className="h-4" src={loading} alt="Loading..." />
                            </Button>
                        ) : (
                            <Button
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                                className="flex gap-2 text-base font-semibold border-none px-4 py-3 hover:bg-black hover:text-white hover:fill-white absolute bottom-5 right-5"
                            >
                                <>
                                    <Sparkles />
                                    Rewrite
                                </>
                            </Button>
                        )}
                    </div>

                    <AspectRatioCardGroup options={aspectRatios} selected={form.watch("aspect_ratio") ?? "SQUARE"}
                        onSelect={handleAspectRatioChange} size="small" label="Aspect ratio" />

                    <div className="flex items-center ">
                        <Button variant="primary-negative" className="min-w-[321px] min-h-[97px] text-base font-semibold hover:bg-brand-yellow hover:border-brand-yellow hover:text-black">Generate image</Button>
                    </div>
                </div>
            </div>

            <div className="space-y-5">
                <div className="flex flex-row gap-6 items-center">
                    <p className="font-semibold text-xl">Select IP vault</p>
                    <p>Optional</p>
                </div>
                <Button onClick={() => setIsIpVaultOpen(true)} variant="primary" type="button" className="flex gap-1 px-5 py-3 border-none hover:bg-black hover:text-white items-start"><><PencilIcon />Select</></Button>
                <IpVaultModal
                    isIpModalOpen={isIpVaultOpen}
                    setIsIpModalOpen={setIsIpVaultOpen}
                    selectedVaults={selectedModels}
                    modelList={modelList}
                    confirmIpSelect={onVaultSelect} />
                {selectedVault && (
                    <div className="bg-gray-100 w-[284px] p-4 rounded-xl flex flex-row gap-3 items-center justify-between">
                        <div className="flex flex-row items-center gap-3">
                            <img className="object-cover h-[70px] w-[70px] rounded-[20px]" src={selectedVault.thumbnail_image} />
                            <div className="flex flex-col">
                                <p className="uppercase text-sm font-semibold">{selectedVault.model_type}</p>
                                <p className="">{selectedVault.name}</p>
                            </div>
                        </div>
                        <div onClick={removeSelectedVault} >
                            <CircleX />
                        </div>
                    </div>
                )}
            </div>

        </div>
    )

}

export default ImageDetails;

