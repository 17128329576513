import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import { Button } from "./button";
import cn from "../../../lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./base/dialog";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import "./dialog_modal.css";
export interface DialogModalProps {
  header?: string;
  description?: string;
  trigger?: any; // remove
  hasClose?: boolean;
  children?: any;
  actions?: any[];
  isOpen?: boolean;
  onClose?: any;
  onOpenChange: any;
  className?: string;
  variant?: string;
}

export function DialogModal({
  hasClose,
  header,
  description,
  actions,
  children,
  isOpen,
  onClose,
  onOpenChange,
  className,
  variant = "default",
}: DialogModalProps) {
  let v = "sm:max-w-lg bg-white p-10 pt-10 rounded-xl";
  if (variant === "screen") {
    v = "w-screen h-screen max-w-none text-center rounded-none";
  }
  if (variant === "large") {
    v = "max-w-none w-[1200px] h-[658px] rounded-xl bg-white";
  }
  if (variant === "xlarge") {
    v = "max-w-none w-[1200px] h-[733px] rounded-xl bg-white";
  }

  return (
    <Dialog open={isOpen} onOpenChange={() => onOpenChange(false)}>
      {/* <DialogTrigger asChild>{trigger}</DialogTrigger> */}
      <DialogContent className={cn(v, className)}>
        <div
          className={cn(
            "absolute opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground hover:cursor-pointer",
            variant === "screen" ? "right-12 top-12" : "right-10 top-4"
          )}
          onClick={() => onClose()}
        >
          {variant === "screen" ? (
            <Button variant="outline" className="gap-2">
              <>
                Close
                <XCircleIcon height={32} />
              </>
            </Button>
          ) : (
            <XMarkIcon height={24} />
          )}
        </div>
        {header && (
          <DialogHeader>
            <DialogTitle>{header}</DialogTitle>
            {description && <DialogDescription>Anyone who has this link will be able to view this.</DialogDescription>}
          </DialogHeader>
        )}
        <div className={cn("flex items-center justify-center", variant === "screen" ? "h-screen" : "")}>{children}</div>
        <DialogFooter className="sm:justify-start">
          {hasClose && (
            <DialogClose asChild>
              <Button type="button" variant="outline-negative">
                Close
              </Button>
            </DialogClose>
          )}
          {actions && actions.map((action) => action)}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
