import React, { useEffect, useState, useRef, useMemo } from "react";
import SubHeader from "../layouts/sub_header";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { GenerateMediaResult, GenerativeModel } from "./models/image_generator";
import "./components/image_details.css";
import Heading from "../../common/components/heading";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { Button } from "../../common/components/ui/button";
import { IconArrowRight, EditPromptIcon, TrashIcon, MiniVerifiedLogo } from "../../common/icons/icons";
import { aspectRatioOptions } from "./models/constants";
import { useFileAPI } from "../../api/file_api";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";

interface EnrichedGeneratedMediaResult extends GenerateMediaResult {
  aspectRatioDetail?: string;
  modelData?: GenerativeModel[];
}

const MyGeneratedImages = () => {
  const [myGeneratedImages, setMyGeneratedImages] = useState<GenerateMediaResult[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<EnrichedGeneratedMediaResult | null>(null);
  const { getMyImages, getModelById, deleteImage } = useGenerativeAPI();
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { uploadFile } = useFileAPI();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { initStarterImage } = useGenerativeAPI();
  const { profile, status } = useAppSelector((state: any) => state.user);

  const navigate = useNavigate();

  const fetchMyImages = async () => {
    try {
      const response = await getMyImages();
      const enrichedImages = await Promise.all(
        response.map(async (image: GenerateMediaResult) => {
          const modelData = await Promise.all(
            image.model_weights?.map((weight) =>
              getModelById(weight.id).then((response) => response).catch((err) => {
                console.warn("Error fetching model data for " + weight.id, err);
                return null;
              })
            ).filter(it => it != null) || []
          );

          const aspectRatioDetail = aspectRatioOptions.find(
            (option) => option.key === image.aspect_ratio
          );


          return {
            ...image,
            modelData,
            aspectRatioDetail: aspectRatioDetail ? `${aspectRatioDetail.label} ${aspectRatioDetail.description}` : "Unknown",
          };
        })
      );

      setMyGeneratedImages(enrichedImages);
    } catch (error) {
      console.error("Error fetching generative output", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyImages();
  }, []);

  const openImageDetails = (image: GenerateMediaResult) => () => {
    setSelectedImage(image);
    setOpenModal(true);
  }

  const deleteMyImage = (taskQueueId: string) => async () => {
    try {
      await deleteImage(taskQueueId);

      setOpenModal(false);
      setSelectedImage(null);

      fetchMyImages();

    } catch (error) {
      console.error("Error deleting image", error);
    }
  }

  const navigateToCreateImage = () => {
    navigate("/image/new");
  }

  const browseForStarterImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = event.target.files ? Array.from(event.target.files) : [];
    if (files.length > 0) {
      setUploading(true);
      uploadFile(
        {
          files: files,
          is_public: false,
          generate_thumbnail: false,
          object_id: profile.id,
          object_type: "user",
          usage: "starter_image",
        },
        (progressEvent) => {
          setUploadProgress((progressEvent.progress || 0) * 100);
        }
      ).then((response) => {
        setUploading(false);
        if (response.length > 0 && response[0].id) {
          initStarterImage({ file_id: response[0].id }).then((response) => {
            navigate("/image/editor/" + response.id);
          }).catch((error) => {
            console.error(error);
          })
        }
      });

    }
  };
  return (
    <div className="page_content">
      <DialogModal
        isOpen={openModal}
        onOpenChange={setOpenModal}
        onClose={() => { setOpenModal(false); setSelectedImage(null) }}
        variant="large"
      >
        {selectedImage &&
          <div className="flex flex-row p-10 gap-16">
            <img className="w-[452px] rounded-lg" src={selectedImage.outputs[0].permalink} />
            <div className="flex flex-col gap-4 justify-center">
              <Heading as="h3">Image details</Heading>
              <p> {selectedImage.prompt}</p>
              <p><span className="font-semibold">Resolution:</span> {selectedImage.aspectRatioDetail}</p>
              {selectedImage.modelData?.filter(it => it != null).map((model, index) => (
                <p key={index}>
                  <span className="font-semibold">
                    {model.entity_type === "USER_PRODUCT" ? "Product: "
                      : model.entity_type === "BRAND" ? "Brand: "
                        : model.entity_type === "LICENSABLE_PROPERTY" ? "Talent: "
                          : "IP Valut: "}
                  </span> {model.name}
                </p>
              ))}

              {/* TODO Apply for license */}
              {/* <p className="font-semibold">License: <Button className="text-black font-semibold outline-none" variant="link"><>Apply for a license <IconArrowRight /> </></Button></p> */}
              <div className="w-full flex justify-between">
                <Button variant="outline-official" className="rounded-xl flex gap-2 justify-start hover:fill-white" onClick={() => navigate(`/image/editor/${selectedImage.task_queue_id}`)}><><EditPromptIcon />Edit image</></Button>
                <Button variant="outline-official" className="rounded-xl flex gap-2 justify-start hover:fill-white" onClick={deleteMyImage(selectedImage.task_queue_id || "")}><><TrashIcon />Delete image</></Button>
              </div>
            </div>

          </div>
        }

      </DialogModal>

      {loading ? <div>Loading...</div> :
        <div>
          <div className="text-white px-[120px] pt-16 pb-24">
            <h1 className="text-5xl pb-9">Create image</h1>
            <div className="w-1/2 flex flex-col gap-9">
              <div className="flex gap-9">
                <div>
                  <p className="italic text-xl">Start a new image</p>
                  <p>Write a description and have us create your image</p>
                </div>
                <Button onClick={navigateToCreateImage} className="text-black inline-flex self-start" variant="primary">Get started</Button>
              </div>

              <div className="flex gap-9">
                <div>
                  <p className="italic text-xl">Edit existing</p>
                  <p>Upload a starter image to edit directly</p>
                </div>
                <Button onClick={browseForStarterImage}
                  className="text-black inline-flex self-start"
                  variant="primary"
                  disabled={uploading}>{uploading ? "Uploading file ..." : "Upload starter image"}</Button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          </div>
          {myGeneratedImages.length > 0 && <p className="text-white font-semibold px-[120px] pb-6">Saved images</p>}
          <div className={"flex flex-row flex-wrap px-[120px] gap-[52px]"}>
            {myGeneratedImages.length > 0 && myGeneratedImages.map((image, index) => (
              <div key={index} onClick={openImageDetails(image)} className={"image-card h-[460px] w-[360px] rounded-2xl"}>
                <div className="image-card-inner"
                  style={{
                    backgroundImage: image.outputs[0].permalink
                      ? `linear-gradient(180deg, rgba(20, 20, 20, 0.00) 40.22%, #141414 100%), url(${image.outputs[0].permalink})`
                      : "none",
                  }}>
                  <div className="absolute top-5 right-5">
                    <MiniVerifiedLogo /></div>
                </div>
              </div>

            ))}
          </div>
        </div>

      }
    </div >
  )

}

export default MyGeneratedImages;