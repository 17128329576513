import React, { useCallback, useEffect, useState } from "react";
import "./image_generator.css";
import Heading from "../../common/components/heading";
import { Button } from "../../common/components/ui/button";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    GenerateMediaResult,
    GenerativeModel,
    GenerativeModelWeight,
    GenerativeOutput,
    UpscaleRequest
} from "./models/image_generator";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import InpaintModal from "./components/modal-components/inpaint_modal";
import {
    CopyIcon,
    DownloadIcon,
    EditAspectRatioIcon,
    EditDirectlyIcon,
    EditPromptIcon,
    IconArrowCurrentFill,
    OfficialAiLogo,
    SaveIcon,
    SelectedGenIcon,
    UpscaleIcon,
} from "../../common/icons/icons";
import LoadingPage from "./loading_page";
import AspectRatioContent from "./components/modal-components/aspect_ratio";
import EditPrompt from "./components/modal-components/edit_prompt";
// import SaveImageContent from "./components/modal-components/save_image";

const GeneratedImagePage = () => {
    const location = useLocation();
    // const generativeData = location.state?.generativeData;
    const { taskQueueId: initialTaskQueueId } = useParams<{ taskQueueId: string }>();
    const [taskQueueId, setTaskQueueId] = useState<string | null>(initialTaskQueueId || null);
    const [generativeData, setGenerativeData] = useState<GenerateMediaResult | null>(null);
    const [selectedVariant, setSelectedVariant] = useState<GenerativeOutput>();
    const [variations, setVariations] = useState<GenerativeOutput[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [showLoadingPage, setShowLoadingPage] = useState(false);
    const [progress, setProgress] = useState({ percent_complete: 0, progress_message: "Generating image..." });
    const [inpaintingView, setInpaintingView] = useState(false);
    const [modelList, setModelList] = useState<GenerativeModel[]>([]);
    const [headerText, setHeaderText] = useState<string>("");


    const [scale, setScale] = useState(1);

    const [actionToast, setActionToast] = useState<
        {
            showToast: boolean,
            toastMessage: string,
        }>({
            showToast: false,
            toastMessage: "",
        });
    // TODO combine vertical modal & modal content
    const [modalContent, setModalContent] = useState<React.ReactNode>(null);
    const [isVerticalModal, setIsVerticalModal] = useState(false);
    const hasFetched = React.useRef(false);

    const { getGenerativeOutput, getModels, saveMyImage, upscaleImage, generateImageMedia, checkTaskQueueStatus } = useGenerativeAPI();
    const navigate = useNavigate();
    let intervalId: NodeJS.Timeout;

    const headerTexts = [
        "Ta-da! Your image is ready.",
        "Done and done! Your image is ready.",
        "Boom! Your image is here."
    ]

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * headerTexts.length);
        setHeaderText(headerTexts[randomIndex]);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productResponse, brandResponse, talentResponse] = await Promise.all([
                    getModels("USER_PRODUCT"),
                    getModels("BRAND"),
                    getModels("LICENSABLE_PROPERTY"),
                ]);

                const combinedModels = [
                    ...productResponse.map(model => ({ ...model, model_type: "Product" })),
                    ...brandResponse.map(model => ({ ...model, model_type: "Brand" })),
                    ...talentResponse.map(model => ({ ...model, model_type: "Talent" })),
                ]
                setModelList(combinedModels);
            } catch (error) {
                console.error("Error fetching models", error);
            }
        };
        fetchData();
    }, []);


    const downloadImage = () => {
        if (!generativeData) return;
        const link = document.createElement("a");
        link.href = selectedVariant ? selectedVariant.permalink : generativeData?.outputs[0].permalink;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast("Image downloaded");
    }

    const saveImage = async () => {
        setModalOpen(false);
        if (!taskQueueId || !generativeData) {
            showToast("Error saving image");
            return;
        }
        try {
            await saveMyImage(taskQueueId);
            showToast("Image saved to My Images");
        } catch (error) {
            showToast("Error saving image");
            console.error("Error saving image", error);
        }
    }

    const fetchUpscaleImage = async () => {
        // eslint-disable-next-line no-debugger
        debugger;
        if (!selectedVariant || !taskQueueId) {
            showToast("Error upscaling image");
            return;
        }

        const payload: UpscaleRequest = {
            generative_output_id: selectedVariant.generative_output_id,
            file_id: selectedVariant.file_id,
            seed: generativeData?.seed,
            parent_task_id: generativeData?.task_queue_id
        }

        try {
            setShowLoadingPage(true);
            const response = await upscaleImage(payload);
            setTaskQueueId(response.id);
            intervalId = setInterval(() => checkStatus(response.id, intervalId), 1000);
            setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000);
        } catch (error) {
            console.error();
            showToast("Error upscaling image");
        }
    }

    // TODO consolidate methods
    const handleSavePrompt = useCallback((newPrompt: string) => {
        setModalOpen(false);
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, prompt: newPrompt }
        });

        regenerateImage(newPrompt, generativeData?.aspect_ratio, generativeData?.model_weights, undefined, generativeData?.task_queue_id, "generate_media");
    }, [generativeData]);

    // TODO fix regenerateImage methods
    const regenerateImage = async (prompt?: string, aspect_ratio?: string, model_weights?: GenerativeModelWeight[], image_file_id?: string, parent_task_id?: string, action?: string) => {
        setShowLoadingPage(true);
        try {
            const response = await generateImageMedia({
                prompt: prompt || generativeData?.prompt || "",
                aspect_ratio: aspect_ratio || "SQUARE",
                image_file_id: image_file_id || undefined,
                model_weights: model_weights || generativeData?.model_weights || [],
                seed: generativeData?.seed,
                parent_task_id: parent_task_id || undefined,
                action: action || "generate_media"
            });
            setTaskQueueId(response.id);

            intervalId = setInterval(() => checkStatus(response.id, intervalId), 1000);
            setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000);
        } catch (error) {
            console.error("Error generating image", error);
        }
    }

    const generateVariations = async () => {
        let prompt_text = generativeData?.prompt || null;
        if (!prompt_text) {
            // TODO: Get a real dialog box here
            prompt_text = prompt("Please describe what you would like to see in the variation")
        }
        if (!prompt_text) {
            prompt_text = "Generate variations"
        }
        return regenerateImage(
            prompt_text,
            generativeData?.aspect_ratio,
            generativeData?.model_weights,
            selectedVariant?.file_id,
            generativeData?.task_queue_id,
            "generate_media")
    }


    const buttonMap = [
        {
            label: "Generate variation",
            icon: <CopyIcon />,
            action: () => generateVariations()
        },
        {
            label: "Upscale",
            icon: <UpscaleIcon />,
            action: () => fetchUpscaleImage()
        },
        {
            label: "Download",
            icon: <DownloadIcon />,
            action: () => downloadImage()
        },
        {
            label: "Edit aspect ratio",
            icon: <EditAspectRatioIcon />,
            action: () => openModal(<AspectRatioContent currentAspectRatio={generativeData?.aspect_ratio} onSave={editAspectRatio} />)
        },
        {
            label: "Edit directly",
            icon: <EditDirectlyIcon />,
            action: () => { setInpaintingView(true) }
        },
        {
            label: "Edit prompt",
            icon: <EditPromptIcon />,
            action: () => openModal(<EditPrompt
                prompt={generativeData?.prompt || ""}
                onSave={handleSavePrompt} selectedModels={generativeData?.model_weights} />
            )
        },
        {
            label: "Save",
            icon: <SaveIcon />,
            // Save image displays toast until functionality for saving to project is implemented
            // action: () => openModal(SaveImageContent(saveImage), true),
            action: () => saveImage()
        },
    ]

    const editAspectRatio = useCallback((newAspectRatio: string) => {
        setModalOpen(false);
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, aspect_ratio: newAspectRatio }
        });
        regenerateImage("", newAspectRatio, generativeData?.model_weights, selectedVariant?.file_id, generativeData?.id, "outpainting");
    }, [generativeData, selectedVariant]);

    useEffect(() => {
        if (!hasFetched.current) {
            if (taskQueueId) {
                const fetchGenerativeOutput = async () => {
                    try {
                        const response = await getGenerativeOutput(taskQueueId);
                        setGenerativeData(response);
                        const variations = response.outputs.map(output => ({
                            generative_output_id: output.generative_output_id,
                            file_id: output.file_id,
                            permalink: output.permalink,
                            asset_type: output.asset_type,
                        }))
                        setVariations(variations);
                        setSelectedVariant(variations[0]);
                        // TODO header toast
                        // showToast(headerText);
                    } catch (error) {
                        console.error("Error fetching generative output", error);
                    }
                };

                fetchGenerativeOutput();
                hasFetched.current = true;
            }
        }
    }, [taskQueueId, getGenerativeOutput]);


    const openModal = (content: React.ReactNode, verticalModal?: boolean) => {
        setIsVerticalModal(verticalModal || false);
        setModalContent(content);
        setModalOpen(true);
    }

    const showToast = (message: string) => {
        setModalOpen(false);
        setActionToast({ showToast: true, toastMessage: message });
        setTimeout(() => {
            setActionToast(prevState => ({ ...prevState, showToast: false, toastMessage: "" }));
        }, 3000);
    }


    const generateInpaint = async (inpaintPrompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null, inpaintImageFileId: string | null) => {
        if (!mask) {
            showToast("Please select the area to inpaint");
            return;
        }
        setShowLoadingPage(true);
        try {
            const response = await generateImageMedia({
                prompt: generativeData ? generativeData.prompt : inpaintPrompt,
                aspect_ratio: generativeData?.aspect_ratio || "SQUARE",
                image_file_id: selectedVariant?.file_id || "",
                model_weights: modelWeights || [],
                seed: generativeData?.seed,
                mask: mask,
                parent_task_id: generativeData?.task_queue_id || undefined,
                inpaint_prompt: inpaintPrompt,
                inpaint_image_file_id: inpaintImageFileId,
            });
            setTaskQueueId(response.id);

            intervalId = setInterval(() => checkStatus(response.id, intervalId), 1000);
            setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000);
        } catch (error) {
            console.error("Error generating image", error);
        }
    }

    const checkStatus = async (updatedTaskQueueId: string, intervalId: NodeJS.Timeout) => {
        try {
            const response = await checkTaskQueueStatus(updatedTaskQueueId);
            let failedTask = null;
            let completedTask = null;
            for (let i = response.length - 1; i >= 0; i--) {
                if (response[i].status === "completed") {
                    completedTask = response[i];
                    break;
                }
                if (response[i].status === "failed") {
                    failedTask = response[i];
                    break;
                }
            }
            if (failedTask) {
                setShowLoadingPage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + failedTask.progress_message);
                return;
            }
            if (completedTask && completedTask.progress_status === "failed") {
                setShowLoadingPage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + completedTask.progress_message);
                return;
            }

            if (completedTask && completedTask.progress_status === "completed") {
                clearInterval(intervalId);
                setShowLoadingPage(false);
                navigate(`/image/editor/${updatedTaskQueueId}`);
                hasFetched.current = false;
                return;
            }

            const latestTask = response[response.length - 1];

            setProgress({
                percent_complete: latestTask.percent_complete || 0,
                progress_message: latestTask.progress_message
            })

        } catch (error) {
            console.error("Error checking status", error);
        }
    }

    const handleZoomIn = () => { setScale((prev) => Math.min(prev + 0.1, 3)) };
    const handleZoomOut = () => { setScale((prev) => Math.max(prev - 0.1, 0.5)) };

    return (
        <div>
            {showLoadingPage ? <LoadingPage percentComplete={progress.percent_complete} progressMessage={progress.progress_message} /> :
                <div>
                    <DialogModal
                        isOpen={modalOpen}
                        onOpenChange={setModalOpen}
                        onClose={() => { setModalOpen(false); setModalContent(null) }}
                        className={isVerticalModal ? "w-[501px] h-[660px]" : ""}
                        variant={!isVerticalModal ? "large" : ""}
                    >
                        {modalContent}

                    </DialogModal>
                    <InpaintModal
                        isInpaintModalOpen={inpaintingView}
                        setIsInpaintModalOpen={setInpaintingView}
                        selectedVariant={selectedVariant}
                        modelList={modelList}
                        generateInpaint={generateInpaint} />

                    <Button variant="primary-negative" className="mt-[132px] ml-[120px] fill-white hover:bg-brand-yellow hover:text-black hover:fill-black hover:border-brand-yellow"
                        onClick={() => { navigate(`/image/new/${taskQueueId}`, { state: { generativeData } }); }}>
                        <><IconArrowCurrentFill />Start over</></Button>

                    <div className="pt-[24px] px-[120px] flex flex-row gap-10">
                        {generativeData &&

                            <div className="w-[600px] h-auto overflow-hidden rounded-lg">
                                <img
                                    className="w-full h-auto object-cover"
                                    style={{ transform: `scale(${scale})`, transition: "transform 0.2s" }}
                                    src={selectedVariant?.permalink ?? ""}
                                    alt="Generated Image"
                                />
                                {/* <Button onClick={handleZoomIn} className="absolute top-2 right-2 bg-white border-none rounded-lg" variant="outline-official">+</Button>
                                    <Button onClick={handleZoomOut} className="absolute top-2 right-9 bg-white border-none rounded-lg" variant="outline-official">-</Button> */}
                            </div>
                        }


                        <div className="flex flex-col gap-6">
                            <div>
                                <h1 className="mb-3 text-xxl-serif">Image overview</h1>
                                {/* TODO header texts as toasts */}
                                <p>{headerText}</p>
                            </div>
                            {variations.length > 0 &&
                                <div>
                                    <p className="font-semibold pb-6">Variations</p>
                                    <div className="flex flex-row gap-7">
                                        {variations.map((variation, index) => (
                                            <div key={index} className="relative">
                                                <img onClick={() => setSelectedVariant(variation)} className={`bg-gray-400 w-[116px] rounded-lg ${variation === selectedVariant ? "outline outline-4 outline-brand-yellow" : ""}`}
                                                    src={variation.permalink} />
                                                {variation === selectedVariant &&
                                                    <div className="absolute top-2 right-2"> <SelectedGenIcon /> </div>}
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            }
                            <p className="font-semibold">Options</p>
                            <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1">
                                {buttonMap.map((button, index) => (
                                    <Button key={index} variant="outline-official" onClick={button.action}
                                        className="h-[24px] w-full p-6 rounded-[20px] flex gap-5 font-normal border-gray-300 justify-start overflow-hidden hover:fill-white">
                                        <>
                                            <div className="flex-shrink-0">{button.icon}</div>
                                            {button.label}</>
                                    </Button>
                                ))}
                            </div>

                        </div>

                    </div>
                    {/* TODO clear toast */}
                    {actionToast.showToast && <div className="absolute rounded-3xl right-9 bg-black px-6 py-5 text-white w-[453px]">{actionToast.toastMessage}</div>}
                </div>
            }

        </div>
    )

}


export default GeneratedImagePage;
