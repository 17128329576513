import { useAuth0 } from "@auth0/auth0-react";
import { UserProduct } from "../model/user_product";
import { File } from "../model/file";

export function useUserProductAPI() {
  const { getAccessTokenSilently } = useAuth0();

  const fetchUserProduct = async ({ id }: { id: string }): Promise<UserProduct> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<UserProduct>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/user-product/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: UserProduct) => {
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the product " + response.statusText));
        }
      });
    });
  };
  const fetchFilesByUsage = async ({ id, usage }: { id: string, usage: string }): Promise<File[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<File[]>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/user-product/${id}/files?usage=${usage}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: File[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the product files " + response.statusText));
        }
      });
    });
  };


  return { fetchUserProduct, fetchFilesByUsage };
}