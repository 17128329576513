import React from "react";
import { File } from "../../../model/file";
import "./image_dropdown.css"
const ImageDropdown = ({
                           files,
                           selectedFileId,
                           onSelect,
                       }: {
    files: File[];
    selectedFileId: string;
    onSelect: (model: File|null) => void;
}) => {
    return (
        <div className="image-dropdown-radio-group">
            {files.map((file) => (
                <div key={file.id}
                     className={"image-radio-button " + (file.id === selectedFileId ? "selected":"")}
                     style={{backgroundImage: `url(${file.permalink})`}}
                     onClick={() => onSelect(file.id === selectedFileId ? null : file)}
                />
            ))}
        </div>
    );
};

export default ImageDropdown;
