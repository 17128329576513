import React, { useState, useEffect, useMemo } from "react";
import { DialogModal } from "../../../../common/components/ui/dialog_modal";
import { GenerativeModel } from "../../models/image_generator";
import { Button } from "../../../../common/components/ui/button";
import Heading from "../../../../common/components/heading";
import { IconCheckMark } from "../../../../common/icons/icons";

const IpVaultModal = ({ isIpModalOpen, setIsIpModalOpen, selectedVaults, modelList, confirmIpSelect }: { isIpModalOpen: boolean, setIsIpModalOpen: (isOpen: boolean) => void, selectedVaults: GenerativeModel[], modelList?: GenerativeModel[], confirmIpSelect: (vault: GenerativeModel | null) => void }) => {
    const [selectedIpVault, setSelectedIpVault] = useState<GenerativeModel | undefined>(undefined);

    useEffect(() => {
        setSelectedIpVault(selectedVaults[0] || undefined);
    }, [selectedVaults]);


    const sortedModelList = useMemo(() => {
        if (!modelList) return [];

        const fluxDevModels = modelList.filter(it => it.base_model === "flux-dev");

        return fluxDevModels.sort((a, b) => {
            const aSelected = selectedVaults.some(sv => sv.id === a.id);
            const bSelected = selectedVaults.some(sv => sv.id === b.id);

            if (aSelected && !bSelected) return -1;
            if (!aSelected && bSelected) return 1;
            return 0;
        });
    }, [modelList, selectedVaults]);

    const setIpVault = (vault: GenerativeModel) => {
        if (vault.id === selectedIpVault?.id) {
            setSelectedIpVault(undefined);
        } else {
            setSelectedIpVault(vault);
        }
    }

    const confirmIpSelection = () => {
        if (selectedIpVault) {
            confirmIpSelect(selectedIpVault);
        } else {
            confirmIpSelect(null);
        }
        setIsIpModalOpen(false);
    };


    return (
        <DialogModal
            isOpen={isIpModalOpen}
            onOpenChange={setIsIpModalOpen}
            onClose={() => { setIsIpModalOpen(false) }}
            variant="large"
        >
            <div className="overflow-hidden">
                <Heading className="not-italic font-semibold pt-4" as="h2">Select IP Vault</Heading>
                <div className="space-y-3 h-[470px] overflow-scroll">
                    {sortedModelList.map((vault: GenerativeModel) => (
                        <div onClick={() => setIpVault(vault)} className="bg-gray-100 w-[1080px] p-5 rounded-md flex flex-row justify-between items-center" key={vault.id}>
                            <div className="flex flex-row gap-10">
                                <img className="object-cover h-20 w-28 rounded-xl" src={vault.thumbnail_image} />
                                <div className="flex flex-col justify-center">
                                    <p className="uppercase font-semibold">{vault.model_type}</p>
                                    <p className="italic text-xl font-normal">{vault.name}</p>
                                </div>
                            </div>
                            <div style={{
                                borderRadius: "100%",
                                height: "51px",
                                width: "51px",
                            }}
                                className={` ${selectedIpVault?.id === vault.id ? "border-none" : "border-2 border-gray-300"}`}>
                                <div className={`h-full rounded-full flex items-center justify-center ${selectedIpVault?.id === vault.id ? "bg-brand-yellow" : ""}`}>
                                    {selectedIpVault?.id === vault.id && <IconCheckMark />}
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                <div className="w-full pt-5 flex justify-end">
                    <Button variant="primary-negative" onClick={confirmIpSelection}>Confirm</Button>
                </div>
            </div>
        </DialogModal>
    )

}

export default IpVaultModal;